import React from 'react';
import './footer.scss'

const Footer = () => {
    return (
        <div className='footer pt-2'>
            <p>Prashanth Keth © 2025 </p>
        </div>
    )
}

export default Footer
